function getCategoryInfo(category = '') {
  const categoryInfo = {
    category: '',
    title: ''
  }
  if (category === 'notice') {
    categoryInfo.category = 'notice'
    categoryInfo.title = '공지사항'
  } else if (category === 'faq') {
    categoryInfo.category = 'faq'
    categoryInfo.title = '자주묻는 질문'
  }
  return categoryInfo
}

export default getCategoryInfo

import { Api, makeGetUrl } from '@/libraries/api'

function getArticles(categoryId = '', page = 1, limit = 10, query = '') {
  const url = `/admin/board/${categoryId}/articles`
  const params = {
    page,
    limit,
    query
  }
  return Api().get(makeGetUrl(url, params))
}

function addArticle(categoryId, title, content, isDisplay = false) {
  const url = `/admin/board/${categoryId}/articles`
  const data = {
    title,
    content,
    is_display: isDisplay
  }
  return Api().post(url, data)
}

function getArticle(categoryId, idx) {
  const url = `/admin/board/${categoryId}/articles/${idx}`
  return Api().get(url)
}

function updateArticle(categoryId, idx, title, content, isDisplay = false) {
  const url = `/admin/board/${categoryId}/articles/${idx}`
  const data = {
    title,
    content,
    is_display: isDisplay
  }
  return Api().put(url, data)
}

function deleteArticle(categoryId, idx) {
  const url = `/admin/board/${categoryId}/articles/${idx}`
  return Api().delete(url)
}

export default {
  getArticles,
  getArticle,
  addArticle,
  updateArticle,
  deleteArticle
}

<template>
  <v-form
    ref="form"
    v-model="valid"
    :lazy-validation="lazy"
  >
    <v-container>
      <v-row>
        <v-col cols="12">
          <PageTitleComponent :title="`${pageTitle} 게시글 ${mode_text}`" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="item.title"
            label="제목"
            placeholder="제목을 입력해 주세요"
            hint="제목을 입력해 주세요"
            :rules="[rules.required]"
            clearable
            clear-icon="clear"
            :readonly="item.is_deleted"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="mt-4"
        >
          <p class="subtitle-1">
            디스플레이 여부
          </p>
          <v-switch
            v-model="item.is_display"
            :label="(item.is_display) ? '보임' :'보이지 않음'"
            :readonly="item.is_deleted"
          />
        </v-col>
      </v-row>
      <v-row v-if="item.is_deleted">
        <v-col
          cols="12"
          class="mt-4"
        >
          <p class="subtitle-1">
            삭제 여부
          </p>
          <v-switch
            v-model="item.is_deleted"
            :label="(item.is_deleted) ? '삭제됨' : '삭제되지 않음'"
            :readonly="item.is_deleted"
          />
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col cols="12">
          <p class="subtitle-1">
            내용
          </p>
          <Editor
            v-model="item.content"
            :readonly="item.is_deleted"
            class="mt-2"
          />
        </v-col>
      </v-row>
      <v-row
        justify="center"
        class="mt-5"
      >
        <v-col
          cols="auto"
        >
          <v-btn
            v-if="mode==='add'"
            class="mx-4"
            color="primary"
            @click="saveItem"
          >
            <v-icon small>
              add
            </v-icon>
            <span class="pl-2">글 등록</span>
          </v-btn>
          <v-btn
            v-if="mode==='modify'"
            class="mx-4"
            color="primary"
            @click="updateItem"
          >
            <v-icon small>
              edit
            </v-icon>
            <span class="pl-2">글 수정</span>
          </v-btn>
          <v-btn
            v-if="mode==='modify'"
            class="mx-4"
            color="error"
            @click="deleteConfirmSheetView = !deleteConfirmSheetView"
          >
            <v-icon small>
              delete
            </v-icon>
            <span class="pl-2">삭제</span>
          </v-btn>
          <v-btn
            color=""
            class="mx-4"
            @click="backToListPage"
          >
            <v-icon small>
              list
            </v-icon>
            <span class="pl-2">목록으로</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-bottom-sheet
      v-model="deleteConfirmSheetView"
      persistent
    >
      <v-sheet
        class="text-center"
        height="250px"
      >
        <div class="py-3">
          정말 삭제하시겠습니까?
          <v-btn
            class="mx-3"
            color="primary"
            @click="deleteItem"
          >
            <span>Y</span>
          </v-btn>
          <v-btn
            class="mx-3"
            color=""
            @click="deleteConfirmSheetView = !deleteConfirmSheetView"
          >
            <span>N</span>
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </v-form>
</template>
<script>
import Editor from '../_elements/editor/MavonEditor'
import PageTitleComponent from '../_elements/title/PageTitle'
import getCategoryInfo from './boardInfo'
import boardApi from './api/board'

export default {
  components: {
    Editor,
    PageTitleComponent
  },
  data() {
    return {
      category: '',
      pageTitle: '',
      valid: false,
      lazy: false,
      deleteConfirmSheetView: false,
      mode: '',
      mode_text: '',
      item: {
        idx: undefined,
        title: '',
        content: '',
        is_display: true,
        is_deleted: false
      },
      rules: {
        required: (value) => !!value || '꼭 입력해주세요'
      }
    }
  },
  watch: {
    $route(to) {
      this.initPageMode(to)
    }
  },
  created() {
    const categoryInfo = getCategoryInfo(this.$route.params.category)
    this.pageTitle = categoryInfo.title
    this.category = categoryInfo.category
    this.initPageMode()
  },
  methods: {
    initPageMode() {
      const currentRoute = this.$route
      if (currentRoute.params.idx === 'add') {
        this.mode = 'add'
        this.mode_text = '등록'
      } else {
        this.mode = 'modify'
        this.mode_text = '확인, 수정'
        this.item.idx = parseInt(currentRoute.params.idx, 10)
        this.getContent()
      }
    },
    async getContent() {
      if (this.item.idx === undefined) {
        return
      }
      const res = await boardApi.getArticle(this.category, this.item.idx)
      if (res.data.result === true) {
        this.item = res.data.data.item
        if (this.item.is_deleted === true) {
          this.mode = 'deleted'
          this.mode_text = '확인 (이 글은 삭제된 상태입니다)'
        }
      }
    },
    async saveItem() {
      if (this.$refs.form.validate() === false) {
        return
      }
      const res = await boardApi.addArticle(this.category, this.item.title, this.item.content, this.item.is_display)
      if (res.data.result === true) {
        this.$bus.$emit('successMessage', '등록되었습니다')
        this.backToListPage()
      } else {
        this.$bus.$emit('successMessage', '서버 통신에 문제가 발생했습니다')
      }
    },
    async updateItem() {
      if (this.$refs.form.validate() === false) {
        return
      }
      const res = await boardApi.updateArticle(
        this.category, this.item.idx, this.item.title, this.item.content, this.item.is_display
      )
      if (res.data.result === true) {
        this.$bus.$emit('successMessage', '수정되었습니다')
        this.backToListPage()
      } else {
        this.$bus.$emit('successMessage', '서버 통신에 문제가 발생했습니다')
      }
    },
    async deleteItem() {
      const res = await boardApi.deleteArticle(
        this.category, this.item.idx
      )
      if (res.data.result === true) {
        this.$bus.$emit('successMessage', '삭제되었습니다')
        this.backToListPage()
      } else {
        this.$bus.$emit('successMessage', '서버 통신에 문제가 발생했습니다')
      }
    },
    backToListPage() {
      const latestHistory = this.$store.getters['App/routerHistoryLatest']
      if (latestHistory.name === 'Service.Board.Articles') {
        this.$router.go(-1)
      } else {
        this.$router.push(`/board/${this.category}/articles?page=1`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-data-table {
  ::v-deep table > thead > tr > th {
    background-color: #EEEEEE;
  }
}
.v-skeleton-loader {
  ::v-deep .v-skeleton-loader__list-item {
    padding: 0px !important;
  }
}
</style>
